<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="is-pulled-right">
            <b-field grouped>
              <b-field>
                <b-radio-button
                  v-model="provider"
                  native-value="gp"
                  :disabled="disabled"
                  :loading="loading"
                  type="is-success">
                  <span>geopeitus.ee</span>
                </b-radio-button>
                <b-radio-button
                  v-model="provider"
                  native-value="gc"
                  :disabled="disabled"
                  :loading="loading"
                  type="is-info">
                  <span>geocaching.com</span>
                </b-radio-button>
              </b-field>
              <b-button type="is-primary"
                icon-left="download"
                :disabled="disabled"
                :loading="loading"
                @click="load()">
                Jooksuta
              </b-button>
            </b-field>
          </div>
          <h1 class="title">
            Kasutaja leiud
          </h1>
          <h2 class="subtitle">
            GP / GC Eesti aarete kasutaja logide erinevused
          </h2>
        </div>
      </div>
    </section>
    <section v-if="data.length">
      <div class="container compare-table">
        <section class="modal-card-body">
          <h3 class="subtitle mt-5">
            Logide arv ({{ data.length }}).
          </h3>
          <b-table
            :data="data"
            sortable
            bordered
            narrowed
            hoverable
            paginated
            per-page="50"
            default-sort="no"
            :mobile-cards="true">
            <template v-for="col in columns">
              <b-table-column :key="col.field" v-bind="col" cell-class="cell">
                <template v-slot="props">
                  <common-list-cell
                    :col="col"
                    :row="props.row">
                  </common-list-cell>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </section>
      </div>
    </section>
  </div>
</template>

<script>

import apiService from '@/services/ApiService'
import { error } from '@/utils'
import CommonListCell from '@/components/CommonListCell.vue'

export default {
  name: 'LogsList',
  components: {
    CommonListCell
  },
  data() {
    let data = JSON.parse(localStorage.getItem('user'))
    const providers = []
    if (data && data['gp']) {
      providers.push('gp')
    }
    if (data && data['gc']) {
      providers.push('gc')
    }
    return {
      providers: providers,
      provider: 'gp',
      disabled: providers.length === 0,
      loading: false,
      data: [],
      columns: [
        {
          field: 'no',
          label: 'Nr',
          sortable: true,
          numeric: true,
          width: 60
        },
        {
          field: 'time',
          label: 'Kuupäev',
          searchable: true,
          sortable: true,
          width: 130
        },
        {
          field: 'code',
          label: 'Aarde GC kood',
          searchable: true,
          sortable: true,
          width: 160
        },
        {
          field: 'name',
          label: 'Aare',
          searchable: true,
          sortable: true
        },
        {
          field: 'by',
          label: 'Leidja',
          searchable: true,
          sortable: true
        }
      ]
    }
  },
  methods: {
    load () {
      this.disabled = true
      this.loading = true

      apiService.loadUserFoundLogs(this.provider)
        .then(response => {
          if (response.ok) {
            this.data = response.data.map((log, i) => {
              return {
                no: i + 1,
                time: log.time,
                code: log.cache.code,
                name: log.cache.name,
                url: log.url,
                by: log.by
              }
            })
          }
        })
        .catch(error)
        .finally(() => {
          this.disabled = false
          this.loading = false
        })
    }
  }
}
</script>

<style>

.compare-table table {
  height: 1px;
}
.compare-table table tr {
  height: 100%;
}
.compare-table table tr td.cell {
  height: 100%;
  padding: 0;
}
.compare-table table tr td.cell > div {
  height: 100%;
  padding: 0.25em 0.5em;
}
.compare-table table tr.detail td.cell > div.error {
  filter: brightness(95%)
}

</style>
